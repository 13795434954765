import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
  connect() {
    super.connect()
  }

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value).then(() => this.copied());
  }

  copied() {
    if (!this.hasButtonTarget)
      return;
    this.buttonTarget.content = this.data.get("successContent");
  }
}