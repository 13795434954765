import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti'

export default class extends Controller {
  static targets = [ "output", "output2", "input", "input2", "drawer", "posTL", "posTC", "posTR", "posML", "posMC", "posMR", "posBL", "posBC", "posBR", "textInput1", "textInput2", "textInput3", "textInput4", "frontText", "frontText1", "frontText2", "frontText3", "frontText4", "frontColor", "textColor", "option", "cardId", "attribution", "cardFront", "cardBack", "cardBorder", "stamp", "postMark", "fgInput", "fgColor", "bgInput", "bgColor", "tColor", "bgPattern", "effect" ]

  toggleCustom () {
    var customButton = get.getElementById("customButton");
    customButton.classList.toggle("hidden");
  }

  changeFont (event) {
//    var selection = event.detail.item;
    var selection = event.currentTarget;
    var font = selection.value;
    var frontText = this.frontTextTarget;
    frontText.classList.remove("font-oswald");
    frontText.classList.remove("font-tangerine");
    frontText.classList.remove("font-bungee");
    frontText.classList.remove("font-monoton");
    frontText.classList.remove("font-festive");
    frontText.classList.remove("font-anton");
    frontText.classList.remove("font-pacifico");
    frontText.classList.remove("font-bangers");
    frontText.classList.remove("font-creepster");
    frontText.classList.remove("font-rye");
    frontText.classList.remove("font-titan");
    frontText.classList.add(font);

    selection.checked = !selection.checked;

  }

  setText1 () {
    var textInput = this.textInput1Target;
    var textOutput = this.frontText1Target;
    if (textInput !== null) {
//      var premiumSlider = document.getElementById("card_premium");
//      premiumSlider.checked = true;
    }
    textOutput.innerHTML = textInput.value;
  }

  setText2 () {
    var textInput = this.textInput2Target;
    var textOutput = this.frontText2Target;
    if (textInput !== null) {
//      var premiumSlider = document.getElementById("card_premium");
//      premiumSlider.checked = true;
    }
    textOutput.innerHTML = textInput.value;
  }

  setText3 () {
    var textInput = this.textInput3Target;
    var textOutput = this.frontText3Target;
    if (textInput !== null) {
//      var premiumSlider = document.getElementById("card_premium");
//      premiumSlider.checked = true;
    }
    textOutput.innerHTML = textInput.value;
  }

  setText4 () {
    var textInput = this.textInput4Target;
    var textOutput = this.frontText4Target;
    if (textInput !== null) {
//      var premiumSlider = document.getElementById("card_premium");
//      premiumSlider.checked = true;
    }
    textOutput.innerHTML = textInput.value;
  }

  openDrawer () {
    var drawer = this.drawerTarget;
    drawer.show();
  }

  closeDrawer () {
    var drawer = this.drawerTarget;
    drawer.hide();
  }

  setPosition (event) {
    var position = event.currentTarget.dataset.previewTarget;
    var text = this.frontTextTarget;
    text.classList.remove("posTL");
    text.classList.remove("posTC");
    text.classList.remove("posTR");
    text.classList.remove("posML");
    text.classList.remove("posMC");
    text.classList.remove("posMR");
    text.classList.remove("posBL");
    text.classList.remove("posBC");
    text.classList.remove("posBR");
    text.classList.add(position);
  }

  setTemplate (event) {
    var template = event.currentTarget
    var templateId = template.dataset.previewId;
    var stamp = this.stampTarget;
    var cardId = this.cardIdTarget;
//    var offer = template.dataset.previewOffer;
    if (this.hasCardFrontTarget) {
      var cardFront = this.cardFrontTarget;
    } else {
      var cardFront = null;
    }
    var cardBack = this.cardBackTarget;
    var cardBorder = this.cardBorderTarget;
    var postMark = this.postMarkTarget;
//    var cardOffer = document.getElementById("card-offer");
    var postMarkInput = document.getElementById("card_location");
    var titleInput = document.getElementById("card_name");
    if (this.hasAttributionTarget) {
      var imageAttribution = this.attributionTarget;
    } else {
      var imageAttribution = null;
    }
    var stampBorder = document.getElementById("card-pm");
    var fgColor = template.dataset.previewFg;
    var bgColor = template.dataset.previewBg;
    var bgPattern = template.dataset.previewPattern;
    var tColor = template.dataset.previewT;
    var attribution = template.dataset.previewAttribution;
    var effect = template.dataset.previewEffect;
    if (this.hasBgInputTarget) {
      var bgInput = this.bgInputTarget;
    } else {
      var bgInput = null;
    }
    if (this.hasFgInputTarget) {
      var fgInput = this.fgInputTarget;
    } else {
      var fgInput = null;
    }
    var photoURL = template.dataset.previewCard;
    var templateType = template.dataset.previewType;
//    var premiumSlider = document.getElementById("card_premium");
    if (typeof(cardFront) != 'undefined' && cardFront != null) {
      cardFront.style.backgroundImage = `url(${photoURL})`;
    }
    var stampURL = template.dataset.previewStamp;
    stamp.src = stampURL;
    cardId.value = templateId;
    cardBorder.style.borderColor = fgColor;
    if (fgInput != null) {
      fgInput.value = fgColor;
    }
    cardBack.style.backgroundColor = bgColor;
    if (bgInput != null) {
      bgInput.value = bgColor;
    }
//    cardOffer.innerHTML = offer;
    cardBack.style.color = tColor;
    cardBorder.style.color = tColor;
    if (bgPattern == 'plain') {
      cardBack.style.backgroundImage = `none`;
    } else {
      cardBack.style.backgroundImage = `url('${bgPattern}')`;
    }
    let stampColor = `radial-gradient(transparent 0, transparent 4px, ${fgColor} 4px, ${fgColor})`;
    stampBorder.style.backgroundImage = stampColor;
    if (imageAttribution != null) {
      imageAttribution.innerHTML = attribution;
    }
    if (tColor == "#f9fafb") {
//      postMark.style.color = "#d1d5db";
      postMark.classList.remove('postmark-dark');
      postMark.classList.add('postmark-light');
      postMarkInput.classList.remove('postmark-dark');
      postMarkInput.classList.add('postmark-light');
      titleInput.classList.remove('title-dark');
      titleInput.classList.add('title-light');
    } else {
//      postMark.style.color = "#4b5563";
      postMark.classList.remove('postmark-light');
      postMark.classList.add('postmark-dark');
      postMarkInput.classList.remove('postmark-light');
      postMarkInput.classList.add('postmark-dark');
      titleInput.classList.remove('title-light');
      titleInput.classList.add('title-dark');
    }

    if (effect == "snow") {
      const snow = document.querySelector('#snow_effect');
      const pumpkin = document.querySelector('#pumpkin_effect');
      pumpkin.innerHTML = '';
      snow.innerHTML = '<div class="snowflakes"><div class="snowflake">❅</div><div class="snowflake">❅</div><div class="snowflake">❆</div><div class="snowflake">❄</div><div class="snowflake">❅</div><div class="snowflake">❆</div><div class="snowflake">❄</div><div class="snowflake">❅</div><div class="snowflake">❆</div><div class="snowflake">❄</div></div>';
//      setTimeout(() => {  snow.innerHTML = ''; }, 10000);
    } else {
      if (effect == "pumpkin") {
        const snow = document.querySelector('#snow_effect');
        const pumpkin = document.querySelector('#pumpkin_effect');
        snow.innerHTML = '';
        pumpkin.innerHTML = '<div class="pumpkins"><div class="pumpkin">🕸️</div><div class="pumpkin">🎃</div><div class="pumpkin">💀</div><div class="pumpkin">🎃</div><div class="pumpkin">🕷️</div><div class="pumpkin">🎃</div><div class="pumpkin">💀</div><div class="pumpkin">🎃</div><div class="pumpkin">🕸️</div><div class="pumpkin">🎃</div></div>';
//        setTimeout(() => {  pumpkin.innerHTML = ''; }, 10000);
      } else {
        const snow = document.querySelector('#snow_effect');
        const pumpkin = document.querySelector('#pumpkin_effect');
        snow.innerHTML = '';
        pumpkin.innerHTML = '';
      }
    }
//    if (templateType == "premium") {
//      premiumSlider.checked = true;
//    } else {
//      premiumSlider.checked = false;
//      var textOutput1 = this.frontText1Target;
//      var textOutput2 = this.frontText2Target;
//      var textOutput3 = this.frontText3Target;
//      var textOutput4 = this.frontText4Target;
//      var textInput1 = this.textInput1Target;
//      var textInput2 = this.textInput2Target;
//      var textInput3 = this.textInput3Target;
//      var textInput4 = this.textInput4Target;
//      textOutput1.innerHTML = "";
//      textOutput2.innerHTML = "";
//      textOutput3.innerHTML = "";
//      textOutput4.innerHTML = "";
//      textInput1.value = "";
//      textInput2.value = "";
//      textInput3.value = "";
//      textInput4.value = "";
//    }

  }

  setPattern () {
    var bgOutput = document.getElementById("card-bg");
    this.optionTargets.forEach((el, i) => {
      if (el.checked) {
        bgOutput.style.backgroundImage = `url('${el.dataset.previewAsset}')`;
      }
    })
  }

  setBG () {
    var bgColor = document.getElementById("bgPicker").value;
    var bgOutput = document.getElementById("card-bg");
    var titleOutput = document.getElementById("card-title");
    var noteOutput = document.getElementById("card-note");
    var nameOutput = document.getElementById("card-name");
    var postMark = document.getElementById("post-mark");
    var textColor = this.textColorTarget;
//    if (document.getElementById("card_t_color")) {
//      var tColor = document.getElementById("card_t_color"); //need to handle both card_ and card_template_!
//    }
//    if (document.getElementById("card_template_t_color")) {
//      var tColor = document.getElementById("card_template_t_color"); //need to handle both card_ and card_template_!
//    }
    bgOutput.style.backgroundColor = bgColor;
    bgOutput.style.color = setText(bgColor);

    function setText(hex) {
      var threshold = 130;
      var hRed = parseInt((cutHex(hex)).substring(0,2),16);
      var hGreen = parseInt((cutHex(hex)).substring(2,4),16);
      var hBlue = parseInt((cutHex(hex)).substring(4,6),16);
  
      function cutHex(h) {return (h.charAt(0)=="#") ? h.substring(1,7):h}
  
      var cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
      if (cBrightness > threshold) {
        postMark.style.color = "#4b5563";
        textColor.style.color = "#111827";
        textColor.style.borderColor = "#111827";
        textColor.value = "#111827";
        return "#111827";
      } else {
        postMark.style.color = "#d1d5db";
        textColor.style.color = "#f9fafb";
        textColor.style.borderColor = "#f9fafb";
        textColor.value = "#f9fafb";
        return "#f9fafb";
      }
    }
  }

  setFG () {
    var fgColor = document.getElementById("fgPicker").value;
    var fgOutput = document.getElementById("card-fg");
    var pmOutput = document.getElementById("card-pm");
    fgOutput.style.borderColor = fgColor;
    let stampColor = `radial-gradient(transparent 0, transparent 4px, ${fgColor} 4px, ${fgColor})`;
    pmOutput.style.backgroundImage = stampColor;
  }

  setFront () {
    var frontColor = document.getElementById("frontPicker").value;
    var frontOutput = document.getElementById("frontText");
    frontOutput.style.color = frontColor;
  }

  readURL () {
    var input = this.inputTarget;
    var input2 = this.input2Target;
    var output = this.cardFrontTarget;
    var output2 = this.stampTarget;

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.style.backgroundImage = `url(${reader.result})`;
      }

      reader.readAsDataURL(input.files[0]);

      if (this.hasCardIdTarget) {
        var template = this.cardIdTarget;
        template.value = "";
        if (this.hasAttributionTarget) {
          var imageAttribution = this.attributionTarget;
          imageAttribution.innerHTML = "Custom Karte";
        }
      }
    }

    if (input2.files && input2.files[0]) {
      var reader2 = new FileReader();

      reader2.onload = function () {
        output2.src = reader2.result;
      }

      reader2.readAsDataURL(input2.files[0]);

   }
   
 }

}