// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

//import Carousel from "stimulus-carousel"

//import controllers from "./*_controller.js"
//controllers.forEach((controller) => {
//  application.register(controller.name, controller.module.default)
//})

//import LazyLoader from 'stimulus-lazy-loader';

import PreviewController from "./preview_controller.js"

import FormController from "./form_controller.js"

import SliderController from "./slider_controller.js"

import NavController from "./nav_controller.js"

import Clipboard from './clipboard_controller.js'

import Carousel from './carousel_controller.js'

import ConfettiController from "./confetti_controller.js"

//import FilterController from "./filter_controller.js"

application.register("preview", PreviewController)

application.register("form", FormController)

application.register("slider", SliderController)

application.register("nav", NavController)

application.register("carousel", Carousel)

application.register('clipboard', Clipboard)

application.register('confetti', ConfettiController)

//application.register('filter', FilterController)

//application.register("lazy-loader", LazyLoader);

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
