import { Controller } from '@hotwired/stimulus'
import confetti from 'canvas-confetti'

export default class extends Controller {

  snow() {
    const snow = document.querySelector('#snow_effect');
    const pumpkin = document.querySelector('#pumpkin_effect');
    pumpkin.innerHTML = '';
    snow.innerHTML = '<div class="snowflakes"><div class="snowflake">❅</div><div class="snowflake">❅</div><div class="snowflake">❆</div><div class="snowflake">❄</div><div class="snowflake">❅</div><div class="snowflake">❆</div><div class="snowflake">❄</div><div class="snowflake">❅</div><div class="snowflake">❆</div><div class="snowflake">❄</div></div>';
    setTimeout(() => {  snow.innerHTML = ''; }, 10000);
  }

  pumpkin() {
    const snow = document.querySelector('#snow_effect');
    const pumpkin = document.querySelector('#pumpkin_effect');
    snow.innerHTML = '';
    pumpkin.innerHTML = '<div class="pumpkins"><div class="pumpkin">🕸️</div><div class="pumpkin">🎃</div><div class="pumpkin">💀</div><div class="pumpkin">🎃</div><div class="pumpkin">🕷️</div><div class="pumpkin">🎃</div><div class="pumpkin">💀</div><div class="pumpkin">🎃</div><div class="pumpkin">🕸️</div><div class="pumpkin">🎃</div></div>';
    setTimeout(() => {  pumpkin.innerHTML = ''; }, 10000);
  }

  confetti() {
    const snow = document.querySelector('#snow_effect');
    const pumpkin = document.querySelector('#pumpkin_effect');
    snow.innerHTML = '';
    pumpkin.innerHTML = '';
    const element = document.querySelector('#card');
    const { top, bottom, left, right } = element.getBoundingClientRect();
    setTimeout(() => {  
        confetti({
            particleCount: 150,
            spread: 120,
            origin: {
                x: ((left + right) / 2) / window.innerWidth,
                y: ((top + bottom) / 2) / window.innerHeight
            }
        });
    }, 100);
  }

}