import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  toggleCustom () {
    var customButton = document.getElementById("customize_button");
    if (typeof(customButton) != 'undefined' && customButton != null) {
      customButton.classList.toggle("hidden");
    }
  }
}