import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  openDrawer () {
    var drawer = document.getElementById("drawer");
    drawer.show();
  }

  closeDrawer () {
    var drawer = document.getElementById("drawer");
    drawer.hide();
  }

}